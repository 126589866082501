<template>
	<modal
		class="modal-add-question"
		size="small-medium"
		:hide-footer="true"
		:show-close="true"
		:isEnableKeyUp="false"
		@close="close"
		v-if="show">
		<div slot="header">
			<h5 class="modal-title">{{ $t('Add Question') }}</h5>
		</div>
		<div slot="body">
			<div>
				<!-- Add question box -->
				<add-question-box
					:is-collapseable="false"
					:is-hide-border="true"
          :event="event"
          :selected="selected"
          :parentQuestion="parentQuestion"
          :eventSettings="eventSettings"
					@close="close()"
				/>
			</div>
		</div>
	</modal>
</template>

<script>

import { mapGetters } from 'vuex';
import Modal from '@/components/Modal.vue';
import AddQuestionBox from '@/components/questions/AddQuestionBox.vue';

export default {
  name: 'ModalAccessCodeRequired',
  components: {
    Modal,
    AddQuestionBox,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: null,
    },
    participant: {
      type: Object,
      default: null,
    },
    selected: {
      type: Object,
      default: null,
    },
    parentQuestion: {
      type: Object,
      default: null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      accessCode: null,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
  },
  watch: {
    show() {
      if (!this.show) {
        document.body.classList.remove('modal-frontlined');
      } else {
        document.body.classList.add('modal-frontlined');
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },

  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>

<style lang="scss">
</style>
